import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/LawTrax-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

export const menu: CoreMenu[] = [
  // Apps & Pages
  {
    id: 'apps',
    type: 'section',
    title: '',
    translate: '',
    icon: 'package',
    children: [
      // lead management
      {
        id: 'lead_management',
        title: 'Lead Management',
        translate: 'MENU.APPS.LEAD_MANAGEMENT.COLLAPSIBLE',
        role: ['Admin', 'Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
        type: 'collapsible',
        icon: 'file-text',
        permission: 'folder.view.lead_management',
        children: [
          {
            id: 'manage-lead',
            title: 'Manage Lead',
            translate: 'MENU.APPS.LEAD_MANAGEMENT.MANAGE_LEAD',
            type: 'item',
            role: ['Admin', 'Attorney', 'Paralegal', 'Office Clerk','Law Clerk','Front Desk', 'Admin Assistant','Case Manager', 'Legal Assistant'],
            icon: 'circle',
            url: 'apps/lead-management/manage-lead',
            permission: 'lead.view.manage_lead'
          },
          // {
          //   id: 'template-editor',
          //   title: 'Edit Template',
          //   translate: 'MENU.APPS.LEAD_MANAGEMENT.EDIT_TEMPLATE',
          //   type: 'item',
          //   role: ['Admin', 'Attorney', 'Paralegal'],
          //   icon: 'circle',
          //   url: 'apps/lead-management/template-editor'
          // }
        ]
      },
      // customers
      {
        id: 'customer_management',
        title: 'Customer Management',
        translate: 'MENU.APPS.CUSTOMER.COLLAPSIBLE',
        type: 'collapsible',
        role: ['Admin', 'Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant', 'Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
        icon: 'user-check',
        permission: 'folder.view.customer_management',
        children: [
          {
            id: 'manage_customer',
            title: 'Manage Customer',
            translate: 'MENU.APPS.CUSTOMER.LIST',
            type: 'item',
            role: ['Admin', 'Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'circle',
            url: 'apps/customer-management/manage-customer',
            permission: 'customer.view.manage_customer'
          },
          {
            id: 'manage_recruitment',
            title: 'PERM Process',
            translate: 'MENU.APPS.CUSTOMER.RECRUITMENT',
            type: 'item',
            role: ['Admin', 'Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'zoom-in',
            url: 'apps/customer-management/manage-recruitment',
            permission: 'customer.view.manage_perm'
          },
          {
            id: 'discount',
            title: 'Discount',
            translate: 'MENU.APPS.ADMIN.DISCOUNT',
            type: 'item',
            icon: 'book-open',
            url: 'apps/admin-management/discount',
            permission: 'customer.view.discount'
          },
        ]
      },
      {
        id: 'customer_management_employee',
        title: 'Manage Customer',
        translate: 'MENU.APPS.CUSTOMER.COLLAPSIBLE',
        type: 'item',
        role: ['Employer'],
        icon: 'user-check',
        url: 'apps/customer-management/manage-customer',
        exclude_permission: true
      },
      // Beneficiary
      {
        id: 'beneficiary_management',
        title: 'Beneficiary Management',
        translate: 'MENU.APPS.BENEFICIARY.COLLAPSIBLE',
        type: 'collapsible',
        role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
        icon: 'clipboard',
        permission: 'folder.view.beneficiary_management',
        children: [
          {
            id: 'manage_beneficiary',
            title: 'Manage Beneficiary',
            translate: 'MENU.APPS.BENEFICIARY.LIST',
            type: 'item',
            role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'circle',
            url: 'apps/beneficiary-management/manage-beneficiary',
            permission: 'beneficiary.view.manage_beneficiary'
          }
        ]
      },
      {
        id: 'manage_beneficiary',
        title: 'Manage Beneficiary',
        translate: 'MENU.APPS.BENEFICIARY.COLLAPSIBLE',
        type: 'item',
        role: ['Beneficiary','Employer'],       
        subRole:['Individual','Employee','Employer'],
        icon: 'clipboard',
        url: 'apps/beneficiary-management/manage-beneficiary',
        exclude_permission: true,
        exclude_role: true
      },

      {
        id: 'manage_beneficiary',
        title: 'Manage Petitioner',
        translate: 'MENU.APPS.BENEFICIARY.COLLAPSIBLE',
        type: 'item',
        role: ['Beneficiary'],
        subRole:['Petitioner'],
        icon: 'clipboard',
        url: 'apps/beneficiary-management/manage-beneficiary',
        exclude_permission: true,
        exclude_role: true
      },

      //emails
      {
        id: 'audit_management',
        title: 'Audit Management',
        translate: 'MENU.APPS.AUDIT.COLLAPSIBLE',
        type: 'collapsible',
        role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
        icon: 'pen-tool',
        permission: 'folder.view.audit_management',
        children: [
          {
            id: 'email',
            title: 'Sent Emails',
            translate: 'MENU.APPS.SENTEMAIL.LIST',
            type: 'item',
            role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'mail',
            url: 'apps/audit-management/sentmails',
            permission: 'audit_management.view.manage_emails'
          },

          {
            id: 'calendar',
            title: 'Manage Calendar',
            translate: 'MENU.APPS.CALENDAR',
            type: 'item',
            role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'calendar',
            url: 'apps/audit-management/calendar',
            permission: 'audit_management.view.manage_calendar'
          },

          {
            id: 'todo',
            title: 'Manage Todo',
            translate: 'MENU.APPS.TODO',
            type: 'item',
            role: ['Admin','Attorney', 'Paralegal', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'list',
            url: 'apps/audit-management/todo',
            permission: 'audit_management.view.manage_todo'
          },

    
        ]
      },
      //User Management
      {
        id: 'user_management',
        title: 'User Management',
        translate: 'MENU.APPS.USER.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'user-plus',
        permission: 'folder.view.user_management',
        children: [
          {
            id: 'role_association',
            title: 'Manage Users',
            translate: 'MENU.APPS.USER.ROLE_ASSOCIATION',
            type: 'item',
            icon: 'octagon',
            url: 'apps/user-management/role-association'
          },
          {
            id: 'role',
            title: 'Roles',
            translate: 'MENU.APPS.ADMIN.ROLES',
            type: 'item',
            icon: 'octagon',
            url: 'apps/admin-management/manage-role'
          }
        ]
      },
      //Admin Management
      {
        id: 'admin_management',
        title: 'Admin Management',
        translate: 'MENU.APPS.ADMIN.COLLAPSIBLE',
        type: 'collapsible',
        icon: 'settings',
        permission: 'folder.view.admin_management',
        children: [         
          {
            id: 'workflow_editor',
            title: 'Workflow Editor',
            translate: 'MENU.APPS.ADMIN.WORKFLOW',
            type: 'item',
            icon: 'octagon',
            url: 'apps/admin-management/workflow-editor',
            permission: 'admin_management.view.workflow_editor'
          },
          // {
          //   id: 'pdf_gen',
          //   title: 'PDF Generate',
          //   translate: 'MENU.APPS.ADMIN.WORKFLOW',
          //   type: 'item',
          //   role: ['Admin'],
          //   icon: 'octagon',
          //   url: 'apps/admin-management/pdf-generate'
          // },
          {
            id: "pdf_gen_new",
            title: "PDF Generate New",
            translate: "MENU.APPS.ADMIN.WORKFLOW",
            type: "item",
            icon: "octagon",
            url: "apps/admin-management/pdf-generate-new",
            permission: 'admin_management.view.pdf_generator'
          },
          {
            id: 'lawfirm_info',
            title: 'Manage Lawfirm',
            translate: 'MENU.APPS.ADMIN.LAWFIRM',
            type: 'item',
            icon: 'octagon',
            url: '/apps/admin-management/add-lawfirm',
            permission: 'admin_management.view.manage_lawfirm'
          },
          {
            id: 'lookup_configuration',
            title: 'Configure Lookup',
            translate: 'MENU.APPS.ADMIN.LOOKUPCONFIGURATION',
            type: 'collapsible',
            icon: 'settings',
            permission: 'admin_management.view.configure_lookup',
            children: [
              {
                id: 'lookup',
                title: 'Manage Lookup',
                translate: 'MENU.APPS.ADMIN.LOOKUP',
                type: 'item',
                icon: 'octagon',
                url: 'apps/admin-management/manage-lookup'
              },
              {
                id: "module",
                title: "Manage Module",
                translate: "MENU.APPS.ADMIN.MODULE",
                type: "item",
                icon: "octagon",
                url: "apps/admin-management/manage-module",
              },
            ],
          },
          {
            id: "role_association",
            title: "Manage Template",
            translate: "MENU.APPS.ADMIN.MANAGETEMPLATE",
            type: "item",
            icon: "octagon",
            url: "apps/admin-management/template-management/manage-template",
            permission: 'admin_management.view.manage_template'
          },
          {
            id: "manage_permission",
            title: "Manage Permission",
            translate: "MENU.APPS.ADMIN.MANAGEPERMISSION",
            type: "item",
            icon: "octagon",
            url: "apps/permission/manage-permission",
            permission: 'admin_management.view.manage_permission'
          },
          {
            id: 'settings',
            title: 'Settings',
            translate: 'MENU.APPS.ADMIN.SETTINGS',
            type: 'collapsible',
            icon: 'tool',
            permission: 'admin_management.view.settings',
            children: [
              {
                id: 'fedex',
                title: 'Fedex',
                translate: 'MENU.APPS.ADMIN.FEDEX',
                type: 'item',
                icon: 'facebook',
                url: 'apps/admin-management/fedex'
              },
              {
                id: 'case',
                title: 'Case Config',
                translate: 'MENU.APPS.ADMIN.CASECONFIG',
                type: 'item',
                role: ['Admin'],
                tenant: ['lawtrax-3aloi','lawtraxprod-0rb7l'],
                icon: 'facebook',
                url: 'apps/admin-management/case-config'
              },
              {
                id: 'invoice',
                title: 'Invoice Config',
                translate: 'MENU.APPS.ADMIN.INVOICECONFIG',
                type: 'item',
                icon: 'book-open',
                url: 'apps/admin-management/invoice-settings'
              },
              {
                id: 'service_center_config',
                title: 'Service Center Config',
                translate: 'MENU.APPS.ADMIN.SERVICECENTER',
                type: 'item',
                icon: 'octagon',
                tenant: ['lawtrax-3aloi','lawtraxprod-0rb7l'],
                url: '/apps/admin-management/manage-service-center',
              },
              {
                id: 'discount',
                title: 'Discount',
                translate: 'MENU.APPS.ADMIN.DISCOUNT',
                type: 'item',
                icon: 'book-open',
                url: 'apps/admin-management/discount'
              },
              {
                id: 'quickBooks_settings',
                title: 'QuickBooks Settings',
                translate: 'MENU.APPS.ADMIN.QUICKBOOKSSETTINGS',
                type: 'item',
                icon: 'book-open',
                url: 'apps/admin-management/quickbooks-settings'
              },
            ],
          },
        ]
      },
      //Employer Management
      {
        id: 'employer_management_employee',
        title: 'Case Manager',
        translate: 'MENU.APPS.EMPLOYER.COLLAPSIBLE',
        type: 'item',
        role: ['Employer'],
        icon: 'folder-plus',
        url: 'apps/employer-management/case-manager',
        exclude_permission: true
      },
      // Case Management
      {
        id: 'case_management',
        title: 'Case Management',
        translate: 'MENU.APPS.CASE.COLLAPSIBLE',
        type: 'collapsible',
        role: ['Employee', 'Admin', 'Paralegal', 'Attorney', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
        icon: 'folder-plus',
        permission: 'folder.view.case_management',
        children: [
          {
            id: 'case_manager',
            title: 'Case Manager',
            translate: 'MENU.APPS.CASE.CASE_MANAGER',
            type: 'item',
            role: ['Employee', 'Admin', 'Paralegal', 'Attorney', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'check',
            url: 'apps/case-management/case-manager',
            permission: 'case.view.case_manager'
          },
          {
            id: 'invoice_manager',
            title: 'Invoice',
            translate: 'MENU.APPS.INVOICE.LIST',
            type: 'item',
            role: ['Employee', 'Admin', 'Paralegal', 'Attorney', 'Office Clerk', 'Legal Assistant','Law Clerk','Front Desk', 'Admin Assistant','Case Manager'],
            icon: 'check',
            url: 'apps/invoice/list',
            permission: 'case.view.invoice'
          }
        ]
      },
      //Reports
      {
        id: 'report_management',
        title: 'Reports Management',
        translate: 'MENU.APPS.ADMIN.REPORT',
        type: 'collapsible',
        icon: 'pie-chart',
        permission: 'folder.view.report_management',
        children: [         
          {
            id: 'reports',
            title: 'Reports',
            translate: 'MENU.APPS.ADMIN.REPORT',
            type: 'item',
            icon: 'octagon',
            url: 'dashboard/report',
            permission: 'report.view'
          },
        ]
      }
    ]
  }

];
