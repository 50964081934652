<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<!-- theme customizer -->
<core-sidebar
  name="themeCustomizer"
  class="customizer d-none d-md-block"
  [invisibleOverlay]="true"
  *ngIf="coreConfig.layout.customizer"
>
  <a
    class="customizer-toggle d-flex align-items-center justify-content-center"
    (click)="toggleSidebar('themeCustomizer')"
  >
    <span [data-feather]="'settings'" [class]="'spinner'"></span>
  </a>
  <core-theme-customizer></core-theme-customizer>
</core-sidebar>
<!-- / theme customizer -->


<ng-template #calModal let-modal>
  <div class="modal-header top-margin">
    <h5 class="modal-title" id="myModalLabel160">Calendar </h5>
  </div>
  <div class="modal-body" tabindex="0" ngbAutofocus>
    <div class="row pb-10 mb-10"  *ngFor="let ev of events">
      <div class="col-4 mt-30 ">{{ev.title}} - {{ev.start | date: 'MM/d/yy, h:mm a'}}</div>
      <div class="col-4">
        <label for="select-label" class="form-label">Reminder</label>
        <ng-select  id="user"  name="df" ngDefaultControl [(ngModel)]="ev.period" placeholder="Select Reminder"
        [multiple]="false">
        <ng-option *ngFor="let opt of options" [value]="opt.value">{{opt.label}}</ng-option>
      </ng-select>
      </div>
      <div class="col-4 mt-30"> 
        <button type="button" class="btn btn-outline-primary mr-1 btn-sm" (click)="snooze(ev)" rippleEffect>
          Snooze
        </button>
        <button type="button" class="btn btn-outline-primary btn-sm " (click)="dismissCal(ev)" rippleEffect>
          Dismiss
        </button>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-primary" (click)="dismissAll(events)" rippleEffect>
      Dismiss All
    </button> -->
    <button type="button" class="btn btn-outline-primary" (click)="modal.close()" rippleEffect>
      Cancel
    </button>
  </div>
</ng-template>