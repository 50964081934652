export class ConfigConstants {
    public static MAX_FILE_SIZE_UPLOADER: number = 10 * 1024 * 1024;
    public static FILE_TYPES_UPLOADER: string[] = ['pdf', 'image', 'doc'];
    public static XLSX_TYPES_UPLOADER: string[] = ['xlsx','csv','xls'];
    public static UPLOAD_MAXSIZE_ERROR_MSG = 'Maximum size allowed is ' + (ConfigConstants.MAX_FILE_SIZE_UPLOADER)/(1024*1024) + 'MB';
    public static ALLOWED_FILETYPE_ERROR_MSG = 'Please Select jpg, jpeg, gif, png, doc or pdf file';
    public static ALLOWED_XLS_FILETYPE_ERROR_MSG = 'Please Select xlsx, csv, xls file';

    public static UPLOAD_GENERIC_ERROR_MSG = 'Please select valid file';
    public static UPLOAD_QUEUE_LIMIT_ERROR_MSG = 'Only one file can be uploaded at a time';
    public static TeamRoles  :string[]= ["Paralegal","Legal Assistant","Office Clerk","Law Clerk", "Front Desk","Admin Assistant","Case Manager"];
    public static SESSION_EXPIRE_POPUP_TIME_SECONDS = 300;
} 