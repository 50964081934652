import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionManagementComponent } from './components/permission-management/permission-management.component';
import { PermissionRouting } from './permission.routing';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { HasPermissionDirective } from './directive/has-permission.directive';
import { CopyPermissionComponent } from './components/copy-permission/copy-permission.component';

@NgModule({
  declarations: [
    PermissionManagementComponent,
    HasPermissionDirective,
    CopyPermissionComponent
  ],
  imports: [
    CommonModule,
    ContentHeaderModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    PermissionRouting
  ],
  exports: [HasPermissionDirective],
})
export class PermissionModule { }
