import { NgModule } from '@angular/core';

import { FeatherIconDirective } from '@core/directives/core-feather-icons';
import { RippleEffectDirective } from '@core/directives/core-ripple-effect.directive';
import { InputRefDirective } from '@core/directives/input-space.directive';
import { LabelCaseDirective } from './label-case.directive';

@NgModule({
  declarations: [RippleEffectDirective, FeatherIconDirective,InputRefDirective,LabelCaseDirective],
  exports: [RippleEffectDirective, FeatherIconDirective,InputRefDirective,LabelCaseDirective]
})
export class CoreDirectivesModule {}
