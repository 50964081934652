import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { User, Role } from 'app/auth/models';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth, AngularFireAuthModule, TENANT_ID } from '@angular/fire/compat/auth';
import { UserManagementService } from 'app/main/apps/user-management/user-management.service';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { Router } from '@angular/router';
import { PermissionService } from 'app/main/apps/permission/permisson.service';
import { BeneficiaryManagementService } from 'app/main/apps/beneficiary-managment/beneficiary-management.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;
  public token : any;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(private _http: HttpClient, private _toastrService: ToastrService,
    private _router: Router,
    public afAuth: AngularFireAuth,
    private _userManagementService: UserManagementService,
    private _beneficiaryMangement: BeneficiaryManagementService,
    private permissionService: PermissionService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Admin;
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return this.currentUser && this.currentUserSubject.value.role === Role.Client;
  }

  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this.SignIn(email, password);
    // return this._http
    //   .post<any>(`${environment.apiUrl}/users/authenticate`, { email, password })
    //   .pipe(
    //     map(user => {
    //       // login successful if there's a jwt token in the response
    //       if (user && user.token) {
    //         // store user details and jwt token in local storage to keep user logged in between page refreshes
    //         localStorage.setItem('currentUser', JSON.stringify(user));

    //         // Display welcome toast!
    //         setTimeout(() => {
    //           this._toastrService.success(
    //             'You have successfully logged in as an ' +
    //               user.role +
    //               ' user to LawTrax. Now you can start to explore. Enjoy! 🎉',
    //             '👋 Welcome, ' + user.firstName + '!',
    //             { toastClass: 'toast ngx-toastr', closeButton: true }
    //           );
    //         }, 2500);

    //         // notify
    //         this.currentUserSubject.next(user);
    //       }

    //       return user;
    //     })
    //   );
  }

  async SignIn(email, password) {
    this.currentUserSubject.next(null);
    localStorage.removeItem('currentUser');
    return await this.afAuth.signInWithEmailAndPassword(email, password)
      .then(async (result) => {
        if (result && result.user) {
          // Get user from user-managemnt
          let bene, displayName,beneId, type,dependentID;
          this.token = await result.user.getIdToken(true);
          let headers = new HttpHeaders({
            'Client': await this.afAuth.tenantId,
            'Authorization': `Bearer ${this.token}` });
          let options = { headers: headers };
          const lawtraxUser = await this._userManagementService.handleLogin({userId: result.user.uid}, options);
          if(lawtraxUser.role == 'Beneficiary'){
            bene = await this._beneficiaryMangement.getSpouseEmail({email:lawtraxUser.emailAddress}, options);
            type = bene.beneficiaryType,
            dependentID = bene && bene.dependent ?  bene.dependent.beneficiaryId : '';
            displayName =  (lawtraxUser.customerId ? lawtraxUser?.customer?.customerName : ( bene.dependent ? bene.dependent?.beneficiaryFirstName + ' ' +bene.dependent?.beneficiaryLastName : '' ) );
           beneId = bene?.beneficiaryId; 
          } else if(lawtraxUser.role == 'Employer'){
            type = lawtraxUser.role

          }
          //  else if (lawtraxUser.role == 'Beneficiary' && lawtraxUser.customer){
          //   displayName = lawtraxUser?.customer?.customerName;
          //  }
          
          const user = <User> {
            email: lawtraxUser.emailAddress,
            firstName: lawtraxUser.firstName,
            lastName: lawtraxUser.lastName,
            displayName: displayName ? displayName  :'',
            type : type ? type : '',
            customerId:lawtraxUser?.customerId || '',
            id: result.user.uid,
            password: password,
            token: this.token,
            beneId: beneId,
            dependentId: dependentID,
            role: lawtraxUser.role as Role,
            avatar: result.user.photoURL ?? 'default-user-icon.png',
            tenantId: await this.afAuth.tenantId,
            permissions: lawtraxUser.permissions
          }
          this.permissionService.setCurrentUserPermissions(lawtraxUser.permissions)
          localStorage.setItem('currentUser', JSON.stringify(user));
          // Display welcome toast!
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in as an ' +
                user.role +
                ' user to LawTrax. Now you can start to explore. Enjoy! 🎉',
              '👋 Welcome, ' + user.firstName + '!',
              { toastClass: 'toast ngx-toastr', closeButton: true ,timeOut: 500,}
            );
          }, 2500);
          // notify
          this.currentUserSubject.next(user);

          return user;
        }
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  async signInWithToken(jwtToken){
    let res = <any>await this._http.post(`${Endpoint.LEAD_MICROSERVICE + ApiRoute.EXCHANGE_ESIGN_ANONYMOUS_TOKEN}`, {code: jwtToken}).toPromise();
    let token = res.token
    return await this.afAuth.signInWithCustomToken(token).then(async (result) => {
      let token = await result.user.getIdToken()
      let tenantId = result.user.tenantId
      let anonymousUser = result.user
      const user = <User> {
        email: '',
        firstName: 'user',
        lastName: '',
        customerId:'',
        id: anonymousUser.uid,
        password: '',
        token: await anonymousUser.getIdToken(),
        role: Role.Client,
        avatar: anonymousUser.photoURL ?? 'default-user-icon.png',
        tenantId: await this.afAuth.tenantId
      }
      localStorage.setItem('currentUser', JSON.stringify(user));
      this.currentUserSubject.next(user);
    }).catch((error) => {
      window.alert(error.message)
    })
  }

  /**
   * Refresh token
   *
   */
  async refreshToken() {
    if(this.currentUserValue) { 
      await this.afAuth.onAuthStateChanged(async (user) => {
        if (user) {
          this.token = await user.getIdToken(true);
          let localUser =  JSON.parse(localStorage.getItem('currentUser'));
          localUser.token = this.token;
          localStorage.setItem('currentUser', JSON.stringify(localUser));
          this.currentUserSubject.next(localUser);
        } else {
          this._router.navigate(['/pages/miscellaneous/not-authorized']);
        } 
      }).catch((error) => {
        window.alert(error.message)
      });
    }
  }
  
  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    this.currentUserSubject.next(null);
    localStorage.removeItem('currentUser');
    localStorage.removeItem('master_lookup');
    localStorage.removeItem('caseConfig');

    sessionStorage.clear();
    // location.reload();
    // notify
    // this.currentUserSubject.unsubscribe()
  }
}
