import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { RoleModel } from 'app/main/apps/admin-management/model/role.model';
import { AdminConfigService } from 'app/main/apps/services/admin-config.service';
import { UserRoleModel } from '../../model/user-role.model';
import { UserModel } from '../../model/user.model';
import { UserManagementService } from '../../user-management.service';
import { CustomerManagementService } from 'app/main/apps/customer-management/customer-management.service';
import { BeneficiaryManagementService } from 'app/main/apps/beneficiary-managment/beneficiary-management.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-role-assoc-sidebar',
  templateUrl: './role-assoc-sidebar.component.html',
  styleUrls: ['./role-assoc-sidebar.component.scss']
})
export class RoleAssocSidebarComponent implements OnInit {
  @Input() roles: RoleModel[];
  @Input() set editUser(value: UserModel) {
    this.editUserChanged(value);
  };
  @Output() closeSideBar = new EventEmitter<any>();
  // public roles: RoleModel[];
  public firstName;
  public lastName;
  public userName;
  public selectedRoles;
  public saveError: boolean = false;
  public userId: string;
  public currentUserRoles: string[] = [];
  public active: boolean = false;
  public teams;
  public selectedTeam;
  public selectedCustomer;
  public user = {
    userName: '',
    firstName: '',
    lastName: '',
    active: false,
    role: {},
    customer: {
      customer_id: ''
    }
  }
  public customers = [];
  isDisabled: boolean = false;
  @ViewChild('newRoleForm') form: FormGroup;
  public beneficiries = [];
  public allRoles = [];
  public isVisible: boolean = true;
  /**
   * Constructor
   *
   * @param {CoreSidebarService} _coreSidebarService
   */
  constructor(private _userManagementService: UserManagementService,
    private _adminManagementService: AdminConfigService,
    private _customerManagementService: CustomerManagementService,
    private _beneficiaryManagementService: BeneficiaryManagementService,
    private router: Router) { }

  private async editUserChanged(editUser: any) {
    if (editUser && editUser.userId) {
      // this.roles;
      this.user.userName = editUser.emailAddress;
      if (editUser?.firstName) {
        this.user.lastName = editUser.lastName;
        this.user.firstName = editUser.firstName;
        this.user.role = this.roles?.find(ele => ele.role_name == editUser?.role);
      }
      if (editUser.status) {
        const flag = editUser.status == 'true' ? true : false;
        this.user.active = flag;
      }

      this.user.customer = editUser?.customer
      if (this.user.role['role_name'] == 'Beneficiary' || this.user.role['role_name'] == 'Employer') {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
      this.userId = editUser.userId;
      this.selectedTeam = this.teams?.find(t => t.attorneyId === this.userId);
      this.selectedCustomer = this.customers?.find(c => c.customer_id === editUser.customerId);
      if (this.user.role['role_name'] == 'Beneficiary' && !this.selectedCustomer) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    } else {
      // this.form?.reset();
      this.userId = undefined;
      this.currentUserRoles = [];
    }
  }

  toggleSidebar(name): void {
    this.saveError = false;
    this.closeSideBar.emit(name);
  }

  async submit(form) {
    try {
      var arr: string[] = [];
      this.saveError = false;
      if (form.valid) {
        // let role= this.allRoles.find(ele => ele.role_name == this.user.role)
        arr.push(this.user.role['role_id'])
        const request = <UserRoleModel>{
          uid: this.userId,
          roles: arr,
          disabled: this.active,
          firstName: this.user.firstName,
          lastName: this.user.lastName
        }

        const updateRequest = {
          firstName: this.user.firstName,
          lastName: this.user.lastName,
          //role: (this.user.roles as RoleModel[])?.map(r => r.role_name)[0],
          role: (this.user.role['role_name']),
          "auditInfo": {},
          userId: this.userId,
          customerId: (this.user.customer?.customer_id ? this.user.customer?.customer_id : null),
          status: this.user.active
        }
        let tasks = [];

        if (this.form.touched && this.form.dirty) {
          tasks.push(this._userManagementService.updateUser(updateRequest));
        }
        if (!_.isEqual(request.roles, this.currentUserRoles)) {
          tasks.push(this._userManagementService.updateUserRoles(request));
        }
        const response = await Promise.all(tasks);
        if (tasks.length > 0 && response[0]) {
          this.closeSideBar.emit('role-assoc-sidebar');
        }
      }
    } catch (error) {
      this.saveError = true;
    }
  }

  async ngOnInit(): Promise<void> {
    // [this.customers]  = await Promise.all([this._customerManagementService.getCustomerBasicDetails()]);
    this.allRoles = this.roles.filter(ele => ele.role_name !== 'Beneficiary' && ele.role_name !== 'Employer');
  }


  // async onPermissionChange() {
  //   if (this.user.exsisting_role && this.user.exsisting_role.role_id) {
  //     this.form.controls.exsisting_role.reset();
  //     this.form.controls.copyFromExsistingRole.reset();
  //   }
  // }

  openEditPermission(){
    this.router.navigate(['/apps/permission/manage-permission'], {
      queryParams: { 'userId': this.userId },
    }); 
  }


  changeDropdown() {
    if (this.user.role == 'Beneficiary' || this.user.role == 'Employer') {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
      this.selectedCustomer = undefined;
    }
  }
}