import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, Observer } from 'rxjs';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { PERMISSIONS, ROLE_TO_PERMISSIONS_MAP, ROLES } from './constants'
import { AuthenticationService } from 'app/auth/service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    permissions = {}
    permissionsOnUsers = {}
    permissionObservable = new Subject()
    permissionsOnUsersObservable = new Subject()
    subUsers = []
    constructor(private _httpClient: HttpClient) {
        if (localStorage.currentUser) {
            let currentUser = JSON.parse(localStorage.currentUser)
            setTimeout(() => {
                this.setCurrentUserPermissions(currentUser.permissions)
            }, 0)
        }
    }
    getCurrentUserPermissions() {
        return this.permissionObservable
    }
    getPermissionsOnUsers() {
        setTimeout(()=>{
            this.permissionsOnUsersObservable.next(this.permissionsOnUsers)    
        })
        return this.permissionsOnUsersObservable
    }
    setCurrentUserPermissions(permissionsArr) {
        var permissionsObject = {}
        for (let i in permissionsArr) {
            permissionsObject[permissionsArr[i]] = true
            let permission = permissionsArr[i]
            if (permission.includes('user.permissions')) {
                let permissionTags = permission.split('.')
                if (!this.permissionsOnUsers[permissionTags[2]]) {
                    this.permissionsOnUsers[permissionTags[2]] = []
                }
                this.permissionsOnUsers[permissionTags[2]].push(permissionTags.pop())
            }
        }
        this.permissions = permissionsObject
        this.permissionObservable.next(permissionsObject)
        this.permissionsOnUsersObservable.next(this.permissionsOnUsers)
    }
    hasPermission(permission) {
        return this.permissions && this.permissions[permission]
    }
    async getUserPermissions(userId) {
        let response = await this._httpClient.get(Endpoint.USER_MICROSERVICE + ApiRoute.GET_USER_PERMISSIONS.replace(':userId', userId)).toPromise()
        return response
    }
    async getInlineUserPermissions(userId) {
        let response = await this._httpClient.get(Endpoint.USER_MICROSERVICE + ApiRoute.GET_INLINE_USER_PERMISSIONS.replace(':userId', userId)).toPromise()
        return response
    }

    async getRolePermissions(role) {
        let response = await this._httpClient.get(Endpoint.USER_MICROSERVICE + ApiRoute.GET_ROLE_PERMISSIONS.replace(':role', role)).toPromise()
        return response
    }
    async updateRolePermissions(role, permissions) {
        let data = {
            permissions: permissions,
            role: role
        }
        let response = await this._httpClient.post(Endpoint.USER_MICROSERVICE + ApiRoute.UPDATE_ROLE_PERMISSIONS, data).toPromise()
        return response
    }
    async updateUserPermission(userId, permissions) {
        let data = {
            permissions: permissions,
            userId: userId
        }
        let response = await this._httpClient.post(Endpoint.USER_MICROSERVICE + ApiRoute.UPDATE_USER_PERMISSIONS, data).toPromise()
        return response
    }
    getAllPermissions() {
        return PERMISSIONS
    }
    getRoles() {
        return ROLES
    }
}
