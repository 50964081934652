<div class="p-20">
    <h3>Copying permissions from Role: "{{fromRole}}"</h3>
    <label class="label-font-weight" for="role">Select Role</label>
    <ng-select [(ngModel)]="toRole">
    <ng-option *ngFor="let role of roles" [value]="role.role_name">{{role.role_name}}</ng-option>
    </ng-select>
    <br />
    <button class="btn btn-primary" (click)="submit()">Submit</button>
    <button class="btn" (click)="cancel()">Cancel</button>
</div>
