import { Component, Input } from '@angular/core';

import { CoreMenuItem } from '@core/types';
import { environment } from 'environments/environment';

@Component({
  selector: '[core-menu-vertical-item]',
  templateUrl: './item.component.html'
})
export class CoreMenuVerticalItemComponent {
  @Input()
  item: CoreMenuItem;
  prod = environment.production;
}
