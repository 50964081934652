import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { CoreSidebarService } from '@core/components/core-sidebar/core-sidebar.service';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import Stepper from 'bs-stepper';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CustomerManagementService } from '../../customer-management/customer-management.service';
import { UserModel } from '../model/user.model';
import { UserManagementService } from '../user-management.service';
import { AdminConfigService } from '../../services/admin-config.service';
import { EmailService } from '../../email/services/email.service';
import { AuthenticationService } from 'app/auth/service';
import { GridQueryModel, Page } from '../../modals/common.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-role-association',
  templateUrl: './role-association.component.html',
  styleUrls: ['./role-association.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RoleAssociationComponent implements OnInit {

  public contentHeader: object;
  public users: UserModel[]
  public tempData = [];
  public rows;
  public role: string = 'Admin'
  public selectedOption = 10;
  public ColumnMode = ColumnMode;
  public searchValue = '';
  public editUser: UserModel;
  public attorneys = [];
  public employers = [];
  public beneficiaries = [];
  public attorneyUsers = [];
  public edit : string = '';
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild('confirmDocument') confirmDocument: any;
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild('resendEmailAlert') resendEmailAlert: any;
  private horizontalWizardStepper: Stepper;
  private filteredData = [];
  private searchData = [];
  public roles = [];
  public beneficiaries1 = [];
  public attorneyUsers1 = [];
  public customers = [];
  public customerMap = new Map<string, string>();
  lawfirmName: any;
  subject: any;
  content: any;
  public signature: any;
  public regards: any;
  public page: Page = {
    limit: 10,
    count: 10,
    offset: 0,
    pageSize: 10,
    searchValue: ''
  };
  constructor( private authService: AuthenticationService, private _adminManagementService: AdminConfigService,
     private _emailService: EmailService, private _userManagementService: UserManagementService,
    private _coreSidebarService: CoreSidebarService,   private modelService: NgbModal,
    private _customerManagementService: CustomerManagementService) {
      this.contentHeader = {
        headerTitle: 'Manage Users',
        actionButton: false,
        breadcrumb: {
          type: '',
          links: [
            
          ]
        }
      };
    }

  async ngOnInit(): Promise<void> {
    await this.loadData();
    const options =  {
      linear: false,
      animation: true
    };
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepper1'), options);
    
  }

  async loadData() {
    this.blockUI.start();
    try {
      let request = new GridQueryModel();
      request.offset = this.page.offset * this.page.limit;
      request.limit = typeof this.page.limit == 'string' ? parseInt(this.page.limit) : this.page.limit;
      request.search = this.page.searchValue;
      request.role =  this.role
let response;
      // const response = await this._userManagementService.getUsers(request);
      [this.roles,response]  = await Promise.all([this._adminManagementService.getRoles(),this._userManagementService.getUsers(request)])
      if (response) {
        this.rows = response[0];
        this.page.count = response[1];
       
      }
    } catch (error) {
      throw error;
    } finally {
      this.blockUI.stop();
    }
  }

  async tabChange(type: string){
    this.role = type;
    this.page.offset = 0;
    this.page.searchValue = '';
    await this.loadData()
  }
  
 
  toggleSidebar(name): void {
   this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
 }  

 async closeSideBar(event) {
    if (event === 'role-assoc-sidebar') {
      this.editUser = <UserModel> { };
    }
    this.loadData();
    this._coreSidebarService.getSidebarRegistry(event).toggleOpen();
  }

  addUser(type){
    this.toggleSidebar('add-new-user-sidebar');
    this.edit = 'add';
  }
  editSelectedUser(user, edit) {
    this.editUser = user;
    this.edit = edit;
    this.toggleSidebar('role-assoc-sidebar');
    // this.modelService.open(this.confirmDocument, {
    //   centered: true,
    //   windowClass: 'modal modal-primary',
    //    backdrop: 'static',
    //    size: 'lg'
    // });
  }

  async resendEmail(row) {
    this.blockUI.start();
    try {

      Swal.fire({
        title: 'Are you sure?',
        text: "You want to send the Verification/Activation Link?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it!'
      }).then(async (result) => {
        if (result?.isConfirmed) {
      var templateName = 'EmailVerficationTemplate';
      var loginLink = 'https://'+window.location.host+'/pages/authentication/login-v2';
      const lawfirmResponse = await this._adminManagementService.getLawfirm();
      this.lawfirmName = lawfirmResponse.law_firm_name;
      var loggedinEmail = this.authService.currentUserValue.email;
      var fromName = this.authService.currentUserValue.firstName +" "+this.authService.currentUserValue.lastName;

      let response = await this._emailService.getEmailSignatures(this.authService.currentUserValue.id);
      let currentSignature = response ? response.find(x => x.defvalue ==true) : [];
      this.signature =  (null != currentSignature && '' != currentSignature && undefined != currentSignature.body) ? currentSignature.body : this.lawfirmName;
      this.regards = (null != currentSignature && '' != currentSignature && undefined != currentSignature.body) ? '' : 'Sincerely,';
      
      this.signature = this.signature.replace(/<p/g, '<p  style="margin:0 !important"');
      await this.getEmailContent(templateName);
      this.subject = this.subject.replace(/{lawfirmName}/g, this.lawfirmName).replace( /&amp;/g, '&');
      this.content = this.content.replace(/{loginLink}/g, loginLink).replace(/{firstName}/g, row.firstName).replace(/{regards}/g, this.regards).replace(/{lawfirmName}/g, this.signature);
      await this._userManagementService.passwordReset({subject: this.subject, content: this.content, emailAddress: row.emailAddress, loggedinUserMail: loggedinEmail, fromName: fromName,
      url: loginLink}, {});
      this.resendEmailAlert.fire();
      let userId =  this.authService.currentUserValue.id;
      await this.updateSentMail(templateName, row, userId, loggedinEmail, fromName);
        }
      })
    } catch (error) {
      throw error;
    } finally {
      this.blockUI.stop();
    }
  }

  async filterUpdate(event) {
    const val = this.page.searchValue;
    this.page.offset = 0;
    await this.loadData()
  
  }
  async datatablePageData(event) {
    this.page.offset = event.offset;
    await this.loadData();
  
  }
  
  async clear() {
    if (this.page.searchValue.length == 0) {
      await this.loadData()
    }
  }

  async getEmailContent(templateName : string){
    try {
      let response = await this._emailService.getEmailTemplates('verficationMail');
      (await response).forEach( ele => {
        if (ele.template_name == templateName) {
          this.content = ele.template;
          this.subject = ele.subject;
        }
    });
    } catch (e) {
      console.log(e);
    }
  }

  async updateSentMail(templateName : string,row : any, userId : any, loggedinEmail : string, fromName : string) {
    try {
      let mailObj = {};
      mailObj['fromEmail'] = 'info@lawtrax.com';
      mailObj['fromName'] = fromName;
      mailObj['replyToEmail'] = loggedinEmail;
      mailObj['toEmail'] = [row.emailAddress];
      mailObj['ccEmail'] = [];
      mailObj['bccEmail'] = [];
      mailObj['body'] = this.content;
      mailObj['subject'] = this.subject;
      mailObj['templateName'] = templateName;
      mailObj['userId'] = userId;
      mailObj['associateId'] = row.beneficiaryId;
      mailObj['sentDate']= new Date();
      await this._emailService.updateSentMail(mailObj);
    } catch (e) {
      console.log(e.errorMessage);
    }
  }

  async reset() {
		this.page.searchValue = '';
		await this.loadData();
	  }
}