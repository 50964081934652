import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { EventRef } from '../admin-management/model/calendar.model';
import { Endpoint } from 'environments/endpoint.prod';
import { ApiRoute } from 'environments/apiroute.prod';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

   /**
    * Constructor
    *
    * @param {HttpClient} _httpClient
    */
   constructor(private _httpClient: HttpClient) {
     
   }
 
   getEvents(): Promise<any> {
    return this._httpClient.get(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_CALENDAR_EVENTS).toPromise();
  }


  async filterEvents(): Promise<any> {
    let events = await this.getEvents();
    let filteredEvents = events.map((schedule) => {
      var event = this._formatEvent(schedule);
      return event;
    });

    return filteredEvents

  }

  convertUTCDateToLocalDate(date) {
    // console.log(date.getTimezoneOffset())
    var newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);
    // newDate.setTime(date.getTime()+date.getTimezoneOffset()*60*1000)
        // console.log(newDate)
    return newDate;   
}

    _formatEvent(eventForm) {
    var newEvent = new EventRef();
    newEvent.id = eventForm.id;
    newEvent.url ='';
    newEvent.title = eventForm.subject;
    let gmtHours = -(new Date(eventForm.start)).getTimezoneOffset()/60;
    newEvent.start = new Date(eventForm.start).toISOString()
    // newEvent.end =  new Date(new Date(eventForm.end).getTime() + gmtHours* 60 * 60 * 1000 ).toISOString();
    newEvent.end = new Date(eventForm.end).toISOString()
    newEvent.calendar = eventForm.calType;
    newEvent.extendedProps.location = eventForm.location;
    newEvent.extendedProps.reminder = eventForm.reminder;
    newEvent.extendedProps.period = eventForm.period;
    newEvent.extendedProps.done = eventForm.done;
    newEvent.extendedProps.description = eventForm.description;
    newEvent.extendedProps.userId = eventForm.userId;
    newEvent.extendedProps.linkId = eventForm.linkId;
    newEvent.extendedProps.toEmails = eventForm.toEmails;
    newEvent.extendedProps.createDate = eventForm.createdDate;
    newEvent.extendedProps.modifiedDate = eventForm.modifiedDate;
    // newEvent.extendedProps.reminderDateTime = new Date(new Date(eventForm.reminderDateTime).getTime() + gmtHours* 60 * 60 * 1000 ).toISOString();
    newEvent.extendedProps.reminderDateTime =new Date(eventForm.reminderDateTime).toISOString()
    newEvent.extendedProps.private = eventForm.private;
    return newEvent;
  }

  _updateFormaEvent(ev){
    let forEvent = {};
    forEvent['id'] =  ev.id;
    forEvent['url'] = ev.url;
    forEvent['subject'] = ev.title;
    forEvent['start'] = ev.start;
    forEvent['end'] = ev.end;
    forEvent['reminderDateTime'] = ev.extendedProps.reminderDateTime;
    forEvent['userId'] = ev.extendedProps.userId
    forEvent['calType'] = ev.calendar;
    forEvent['linkId'] = ev.extendedProps.linkId;
    forEvent['period'] = ev.extendedProps.period
    forEvent['location'] = ev.extendedProps.location
    forEvent['description'] =ev.extendedProps.description;
    forEvent['reminder'] = ev.extendedProps.reminder;
    forEvent['done'] = ev.extendedProps.done;
    forEvent['toEmails'] = ev.extendedProps.toEmails;
    forEvent['recurring'] = ev.extendedProps.recurring;
    forEvent['private'] = ev.extendedProps.private;
    forEvent['createdDate'] = ev.extendedProps.createDate;
    forEvent['modifiedDate'] =  new Date();
    return forEvent
  }
  
   createNewEvent(req) {    
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.CREATE_CALENDER,req).toPromise()

   }
 
   deleteEvent(id){
    return this._httpClient.delete(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.DELETE_CALENDAR_EVENTS+ '/'+id).toPromise();
   }
  
   updateEvent(id,req) {    
    return this._httpClient.put(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.UPDATE_CALENDAR_EVENT+ '/'+id,req).toPromise()

   }
   snoozeDismissEvent(id,req) {    
    return this._httpClient.put(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.SNOOZE_CALENDAR_EVENT+ '/'+id,req).toPromise()

   }
 }

