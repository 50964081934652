// beneficiary
// case
// lead
// customer
// user

export const PERMISSIONS = [
    // add folder permissions and sub folder
    // add actions permission
    // add button in edit user dialog to manage permission
    
    // Folder Permission Start
    {
        "permission_id": "folder.view.lead_management",
        "permission_name": "View Lead Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.customer_management",
        "permission_name": "View Customer Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.beneficiary_management",
        "permission_name": "View Beneficiary Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.audit_management",
        "permission_name": "View Audit Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.admin_management",
        "permission_name": "View Admin Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.case_management",
        "permission_name": "View Case Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.user_management",
        "permission_name": "View User Management Folder",
        "entity": "folder"
    },
    {
        "permission_id": "folder.view.report_management",
        "permission_name": "View report Management Folder",
        "entity": "folder"
    },
    // Folder Permission End
    // Beneficiary Permission Start
    {
        "permission_id": "beneficiary.create",
        "permission_name": "Create Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.view.manage_beneficiary",
        "permission_name": "View Manage Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.view",
        "permission_name": "View Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.update",
        "permission_name": "Update Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.delete",
        "permission_name": "Delete Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.action.send_email",
        "permission_name": "Send Email to Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.action.send_verification_email",
        "permission_name": "Send Verification Email to Beneficiary",
        "entity": "beneficiary"
    },
    {
        "permission_id": "beneficiary.action.add_calendar_event",
        "permission_name": "Add Calendar Event",
        "entity": "beneficiary"
    },
    // Beneficiary Permission End
    // Case Permission Start
    {
        "permission_id": "case.view.case_manager",
        "permission_name": "View Case Manager",
        "entity": "case"
    },
    {
        "permission_id": "case.access.all",
        "permission_name": "Can access all cases",
        "entity": "case"
    },
    {
        "permission_id": "case.create",
        "permission_name": "Create Case",
        "entity": "case"
    },
    {
        "permission_id": "case.view",
        "permission_name": "View Case",
        "entity": "case"
    },
    {
        "permission_id": "case.update",
        "permission_name": "Update Case",
        "entity": "case"
    },
    {
        "permission_id": "case.delete",
        "permission_name": "Delete Case",
        "entity": "case"
    },
    {
        "permission_id": "case.view.invoice",
        "permission_name": "View Invoice",
        "entity": "case"
    },
    {
        "permission_id": "case.action.send_mail_to_company",
        "permission_name": "Send Mail to Company",
        "entity": "case"
    },
    {
        "permission_id": "case.action.send_email_to_beneficiary",
        "permission_name": "Send Email to Beneficiary",
        "entity": "case"
    },

    // Case Permission End
    // Lead Permission Start
    {
        "permission_id": "lead.view.manage_lead",
        "permission_name": "View Manage Lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.access.all",
        "permission_name": "Can access all leads",
        "entity": "lead"
    },
    {
        "permission_id": "lead.create",
        "permission_name": "Create Lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.view",
        "permission_name": "View Lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.update",
        "permission_name": "Update Lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.delete",
        "permission_name": "Delete Lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.convert_to_customer",
        "permission_name": "Convert Lead to Customer",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.convert_to_beneficiary",
        "permission_name": "Convert Lead to Beneficiary",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.send_retainer_agreement",
        "permission_name": "Send Retainer Agreement",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.send_email",
        "permission_name": "Send Email to lead",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.send_intake_form",
        "permission_name": "Send Intake Form",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.convert_to_beneficiary",
        "permission_name": "Convert Lead to Beneficiary",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.add_calendar_event",
        "permission_name": "Add Calendar Event",
        "entity": "lead"
    },
    {
        "permission_id": "lead.action.import",
        "permission_name": "Import Leads",
        "entity": "lead"
    },
    // Lead Permission End
    // Customer Permission Start
    {
        "permission_id": "customer.view.manage_customer",
        "permission_name": "View Manage Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.view.manage_perm",
        "permission_name": "PERM Process",
        "entity": "customer"
    },
    {
        "permission_id": "customer.view.discount",
        "permission_name": "Discount",
        "entity": "customer"
    },
    {
        "permission_id": "customer.create",
        "permission_name": "Create Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.view",
        "permission_name": "View Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.update",
        "permission_name": "Update Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.delete",
        "permission_name": "Delete Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.action.send_email",
        "permission_name": "Send Email to Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.action.send_verification_email",
        "permission_name": "Send Verification Email to Customer",
        "entity": "customer"
    },
    {
        "permission_id": "customer.action.add_calendar_event",
        "permission_name": "Add calendar event",
        "entity": "customer"
    },
    // Customer Permission End
    // User Permission Start
    {
        "permission_id": "user.view.manage_user", 
        "permission_name": "View Manage User",
        "entity": "user"
    },
    {
        "permission_id": "user.view.manage_role", 
        "permission_name": "View Manage Role",
        "entity": "user"
    },
    {
        "permission_id": "user.create",
        "permission_name": "Create User",
        "entity": "user"
    },
    {
        "permission_id": "user.view",
        "permission_name": "View User",
        "entity": "user"
    },
    {
        "permission_id": "user.update",
        "permission_name": "Update User",
        "entity": "user"
    },
    {
        "permission_id": "user.delete",
        "permission_name": "Delete User",
        "entity": "user"
    },
    // User Permission End
    // Admin Management Permission Start   
    {
        "permission_id": "admin_management.view.manage_lawfirm",
        "permission_name": "View Manage Lawfirm",
        "entity": "admin_management"
    },
    {
        "permission_id": "admin_management.view.configure_lookup",
        "permission_name": "View Configure Lookup",
        "entity": "admin_management"
    },
    {
        "permission_id": "admin_management.view.manage_template",
        "permission_name": "View Manage Template", 
        "entity": "admin_management"
    },
    {
        "permission_id": "admin_management.view.manage_permission",
        "permission_name": "View Manage Permission",
        "entity": "admin_management"
    },
    {
        "permission_id": "admin_management.view.settings",
        "permission_name": "View Settings",
        "entity": "admin_management"
    },
    // Admin Management Permission End
    // Audit Management Permission End
    {
        "permission_id": "audit_management.view.manage_emails",
        "permission_name": "View Manage Emails", 
        "entity": "audit_management"
    },
    {
        "permission_id": "audit_management.view.manage_calendar",
        "permission_name": "View Manage Calendar",
        "entity": "audit_management"
    },
    {
        "permission_id": "audit_management.view.manage_todo",
        "permission_name": "View Manage Todo", 
        "entity": "audit_management"
    },
    // Audit Management Permission End
    // Report Management Permission Start
    {
        "permission_id": "report.view",
        "permission_name": "View Report", 
        "entity": "report_management"
    }
    // Report Management Permission End

]

const ROLE_ADMIN = 'admin'
const ROLE_PARALEGAL = 'paralegal'
const ATTORNEY = 'attorney'
const CASE_MANAGER = 'case_manager'

export const ROLES = [
    ROLE_ADMIN,
    ROLE_PARALEGAL,
    ATTORNEY,
    CASE_MANAGER
]

export const ROLE_TO_PERMISSIONS_MAP = {
    ROLE_ADMIN: [
        "beneficiary.create",
        "beneficiary.view",
        "beneficiary.update",
        "beneficiary.delete",
        "case.create",
        "case.view",
        "case.update",
        "case.delete",
        "lead.create",
        "lead.view",
        "lead.update",
        "lead.delete",
        "customer.create",
        "customer.view",
        "customer.update",
        "customer.delete",
        "user.create",
        "user.view",
        "user.update",
        "user.delete",
        "report.view"
    ],
    ROLE_PARALEGAL: [
        "beneficiary.create",
        "beneficiary.view",
        "beneficiary.update",
        "beneficiary.delete"
    ],
    ATTORNEY: [
        "case.create",
        "case.view",
        "case.update",
        "case.delete",
        "report.view"
    ],
    CASE_MANAGER: [
        "case.create",
        "case.view",
        "case.update",
        "case.delete"
    ]
}
