import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationService, HttpCacheService, TimerService } from 'app/auth/service';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  
  constructor(
    private cacheService: HttpCacheService,
    private timerService: TimerService
    ) {}
  
    intercept(
      req: HttpRequest<any>,
      next: HttpHandler
    ): Observable<any> {
      
      this.timerService.startTimer();
      let remainingTimeInMilliseconds = this.timerService.getRemainingTime();
      
      if (remainingTimeInMilliseconds <= 0) {
        
        this.timerService.resetTimer();
        this.cacheService.invalidateCache();
      } 
      
      //check if the outgoing calls are GET
      if (((req.url.indexOf('/beneficiary/get-beneficiary/') > -1 || req.url.indexOf('/case/get-case/') > -1 || req.url.indexOf('/customer/') > -1) && req.method === 'GET' ) ||
          (req.url.indexOf('/case/alerts') > -1 && req.method === 'POST')) {
        // attempt to retrieve a cached response
        const cachedResponse:
          | HttpResponse<any>
          | undefined = this.cacheService.get(req.url);
  
        // return cached response
        if (cachedResponse) {
          return of(cachedResponse);
        }
        // send request to server and add response to cache
        return next.handle(req).pipe(
          tap((event) => {
            if (event instanceof HttpResponse) {
              this.cacheService.put(req.url, event);
            }
          })
        );

      } else if (req.url.indexOf('/case/entity-documents') > -1 && req.method === 'POST') {
        return next.handle(req);
      } else if ((req.method === 'POST' || req.method === 'PUT' || req.method === 'delete')) {
        this.cacheService.invalidateCache();
        return next.handle(req);
      } else {
          // pass along non-cacheable requests 
          return next.handle(req);
      }
    }
}
