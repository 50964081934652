
  <div class="modal-dialog">
    <form class="add-new-user modal-content pt-0" (ngSubmit)="(newRoleForm.form.valid)" #newRoleForm="ngForm">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="toggleSidebar('role-assoc-sidebar')">
        ×
      </button>
      <div class="modal-header mb-1">
        <h5 class="modal-title" id="exampleModalLabel">Associate Role</h5>
      </div>
      <div class="modal-body flex-grow-1">
        <div class="form-group">
          <label class="form-label" for="Role">Role</label>
          <ng-select #selectMultiLimited placeholder="Roles" [items]="allRoles" [multiple]="false" bindLabel="role_name"
            [(ngModel)]="user.role" #FRolesRef="ngModel" name="roles" required
            (change)="changeDropdown()"
            [readonly]="isDisabled">
          </ng-select>
        
        </div>
  
        <div class="form-group"
          *ngIf="(this.user?.role === 'Beneficiary' || this.user?.role === 'Employer') && this.isVisible">
          <label class="form-label" for="Employer">Employer</label>
          <ng-select #selectMultiLimited placeholder="Employer" [items]="customers" [multiple]="false"
            bindLabel="customerName" [(ngModel)]="user.customer" #FCustomerRef="ngModel" name="customer"
            [readonly]="isDisabled" >
          </ng-select>
       
        </div>
  
  
        <div class="form-group">
          <label class="form-label" for="firstName">First Name</label>
          <input type="text" inputRef class="form-control dt-full-name" id="firstName" placeholder="First Name" name="firstName"
            [(ngModel)]="user.firstName"
            #FFirstNameRef="ngModel" required aria-describedby="basic-icon-default-fullname2"  />
        
        </div>
        <div class="form-group">
           <label class="form-label" for="lastName">Last Name</label> 
           <input type="text" inputRef 
            class="form-control dt-full-name" id="lastName" placeholder="Last Name" name="lastName" [(ngModel)]="user.lastName"
            #FLastNameRef="ngModel" required
            aria-describedby="basic-icon-default-fullname2"  />
            
        </div>
        <div class="form-group">
          <label class="form-label" for="userName">User Name</label>
          <input type="text" inputRef class="form-control dt-full-name" id="userName" placeholder="Role Name" name="userName"
            [(ngModel)]="user.userName"  #FNameRef="ngModel" required
            aria-describedby="basic-icon-default-fullname2" disabled />
        
        </div>
        <div class="row col-12" style="padding-bottom: 1em;">
          <div class="col-6 custom-control custom-checkbox"> 
            <input type="checkbox" class="custom-control-input" id="active" name="active" [(ngModel)]="user.active" #FActiveRef="ngModel" /> 
              <label class="custom-control-label"
              for="active">Active</label>
          </div>
        </div>
        <div class="mt-20 mb-20">
          <a class="btn btn-primary mr-1" (click)="openEditPermission()">
            Update Permission <span [data-feather]="'external-link'" [size]="16"
            [class]="'mr-50'"></span>
          </a>
        </div>

  
        <button type="submit" class="btn btn-primary mr-1" (click)="submit(newRoleForm)" rippleEffect>Update</button>
        <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('role-assoc-sidebar')" rippleEffect>
          Cancel
        </button>
  
        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="saveError" style="margin-top: 1em;">
          <div class="alert-body">
            <p>Error processing information...</p>
          </div>
        </ngb-alert>
      </div>
    </form>
  </div>
