import { Interface } from "readline";


export interface Page {
    limit :number,
    count: number,
    offset: number,
    pageSize: number,
    searchValue: string
}

export class GridQueryModel {
    leadType: string | null;
    beneficiaryType: string | null;
    offset: number;
    limit: number;
    search: string | null;
    caseType: string | null;
    caseStatus: string | null;
    role: string | null;
    canViewAll: boolean | null;
    id: string | null;
}