<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">
  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item">
      <a class="navbar-brand" [routerLink]="['/']">
        <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36" />
        </span>
        <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
      </a>
    </li>
  </ul>
  <!--/ Navbar brand -->
</div>

<div class="navbar-container d-flex content">
  <div class="bookmark-wrapper d-flex align-items-center">
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav d-xl-none"
      *ngIf="coreConfig && coreConfig.layout && coreConfig.layout.menu &&  !coreConfig.layout.menu.hidden">
      <li class="nav-item">
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
        </a>
      </li>
    </ul>
  </div>
  <ul class="nav navbar-nav align-items-center ml-auto">
    <!-- Language selection -->
    <li *ngIf=" url && url.length >0 " ngbDropdown class="nav-item dropdown dropdown-language">
      <a [href]="url" target="_blank" class="nav-link dropdown-toggle" id="dropdown-flag">
        <span [data-feather]="'help-circle'" [class]="'ficon'"></span> <span class="selected-language">Help
          Guide</span></a>

    </li>
    <!--/ Language selection -->

    <!-- Toggle skin -->
    <!-- <li class="nav-item d-none d-lg-block">
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      </a>
    </li> -->
    <!--/ Toggle skin -->

    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->

    <!-- Cart 
    <app-navbar-cart></app-navbar-cart>
    Cart -->

    <!-- Notification 
    <app-navbar-notification></app-navbar-notification>
    Notification -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      <a class="nav-link dropdown-toggle dropdown-user-link" id="dropdown-user" ngbDropdownToggle
        id="navbarUserDropdown" aria-haspopup="true" aria-expanded="false">
        <ng-container *ngIf="this.currentUser">
          <div class="user-nav d-sm-flex d-none">
            <span class="user-name font-weight-bolder">{{ this.currentUser.firstName }} {{ this.currentUser.lastName
              }}</span>
              <!-- <span class="user-status"> {{ this.currentUser.role }}</span> -->
              <span *ngIf="currentUser.type == 'Petitioner'">{{ this.currentUser.type }}</span>
              <span *ngIf="currentUser.type != 'Petitioner'">{{ this.currentUser.role }}</span>

              
            <span class="user-name font-weight-bolder" *ngIf="currentUser.role ==  'Beneficiary'">
              <span *ngIf="currentUser.type == 'Employee' && currentUser.displayName">Employer - </span>
              <span *ngIf="currentUser.type == 'Individual' && currentUser.displayName">Spouse - </span> 
              <span *ngIf="currentUser.type == 'Petitioner' && currentUser.displayName">Applicant - </span>
              {{currentUser?.displayName}}
            </span>

          </div>
          <span class="avatar"><img class="round" src="assets/images/portrait/small/{{ this.currentUser.avatar }}"
              alt="avatar" height="40" width="40" /><span class="avatar-status-online"></span></span>
        </ng-container>
      </a>
      <div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
        <!-- <a ngbDropdownItem [routerLink]="['/pages/profile']"
          ><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
        ><a ngbDropdownItem [routerLink]="['/apps/email']"
          ><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
        ><a ngbDropdownItem [routerLink]="['/apps/todo']"
          ><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
        ><a ngbDropdownItem [routerLink]="['/apps/chat']"
          ><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
        >
        <div class="dropdown-divider"></div> -->
        <a ngbDropdownItem [routerLink]="['/pages/account-settings']"><span [data-feather]="'settings'"
            [class]="'mr-50'"></span> Profile</a>
        <!-- <a ngbDropdownItem [routerLink]="['/pages/pricing']"
          ><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
        ><a ngbDropdownItem [routerLink]="['/pages/faq']"
          ><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a
        > -->
        <a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
      </div>
    </li>
    <!--/ User Dropdown -->
  </ul>
</div>