import { Component, OnInit, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-copy-permission',
  templateUrl: './copy-permission.component.html',
  styleUrls: ['./copy-permission.component.scss']
})
export class CopyPermissionComponent implements OnInit {

  @Output() onSubmit = new EventEmitter();
  @Input() fromRole: string ;
  @Input() roles: any = [];
  toRole: string;
  constructor() { }

  ngOnInit(): void {
  }

  submit(){
    this.onSubmit.emit(this.toRole);
  }

  cancel(){
    this.onSubmit.emit(null);
  }

}
