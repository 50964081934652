import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { Observable } from 'rxjs';

@Injectable()
export class DocumentManagementService {

    /**
     * Constructor
     *
     * @param {HttpClient} _httpClient
     */
    constructor(private _httpClient: HttpClient) {
    }

    saveDoument(document: any): Promise<any> {
        return this._httpClient.post(Endpoint.DOCUMENT_MICROSERVICE + ApiRoute.SAVE_DOCUMENT, document).toPromise();
    }

    getDocumentBlob(fileName: string): Observable<Object>{
        return this._httpClient.get(Endpoint.DOCUMENT_MICROSERVICE  + ApiRoute.GET_BLOB_DOCUMENT + fileName);
    }

    getSignedDocuemntUrl(fileName: string): Promise<any>{
        return this._httpClient.get(Endpoint.DOCUMENT_MICROSERVICE  + ApiRoute.GET_SIGNED_DOCUMENT + fileName).toPromise();
    }

    getSignedUploadFileLink(fileName: string): Promise<any>{
        return this._httpClient.get(Endpoint.DOCUMENT_MICROSERVICE  + ApiRoute.GET_SIGNED_UPLOAD_DOCUMENT + fileName).toPromise();
    }

    deleteDocument(fileName: string): Promise<any> {
        return this._httpClient.post(Endpoint.DOCUMENT_MICROSERVICE + ApiRoute.DELETE_DOCUMENT, { fileName: fileName }).toPromise();
    }

    fillPdf(request: any): Promise<any>  {
        return this._httpClient.post(Endpoint.DOCUMENT_MICROSERVICE + ApiRoute.FILL_PDF, request).toPromise();
    }

    saveGeneratedDoument(document: any): Promise<any> {
        //let options = { headers: { 'Content-Type': 'multipart/form-data' } };
        return this._httpClient.post(Endpoint.DOCUMENT_MICROSERVICE + ApiRoute.SAVE_GENERATED_DOCUMENT, document).toPromise();
    }

    mergeCaseDoument(request: any): Promise<any> {
        return this._httpClient.post(Endpoint.DOCUMENT_MICROSERVICE + ApiRoute.MERGE_CASE_DOCUMENTS, request).toPromise();
    }

    signedUpload(file, url) {
        const formData = new FormData();
        formData.append('file', file);
        let options = { headers: { 'Content-Type': 'application/pdf',  'enctype' : 'multipart/form-data'} };
        //return this._httpClient.put(url, file, options).toPromise();
        return this._httpClient.post(url, formData, options).toPromise();
    }
}
