import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { GridQueryModel } from '../modals/common.model';
import { BeneficiaryCustomerListModel } from './model/beneficiary-customer-list.model';
import { BeneficiaryDependentModel } from './model/beneficiary-dependent.model';
import { BeneficiaryModel } from './model/beneficiary.model';
import { DocumentModel } from './model/document.model';
import { F1Model } from './model/f1.model';
import { GcModel } from './model/gc.model';
import { GetBeneficiaryResponse } from './model/get-beneficary-response';
import { H1bModel } from './model/h1b.model';
import { I485Model } from './model/i485.model';
import { I94Model } from './model/i94.model';
import { LcaModel } from './model/lca.model';
import { PassportModel } from './model/passport.model';
import { SaveBeneficiaryDependentModel } from './model/save-beneficiary-dependent.model';
import { SaveBeneficiaryEducationModel } from './model/save-beneficiary-education.model';
import { SaveBeneficiaryModel } from './model/save-beneficiary-request.model';
import { SaveF1Model } from './model/save-f1.model';
import { SaveGcModel } from './model/save-gc.model';
import { SaveH1bModel } from './model/save-h1b.model';
import { SaveI485Model } from './model/save-i485.model';
import { SaveI94Model } from './model/save-i94.model';
import { SaveLcaModel } from './model/save-lca.model';
import { SavePassportModel } from './model/save-passport.model';
import { SaveVisaModel } from './model/save-visa.model';
import { SaveWorkExperienceDetailModel } from './model/save-work-experience-detail.model';
import { VisaModel } from './model/visa.model';

@Injectable({
  providedIn: 'root'
})
export class BeneficiaryManagementService {

  public primaryRoute: string = Endpoint.BENEFICIARY_MICROSERVICE + "/beneficiary";
  activeTab = 1
/**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
 constructor(private _httpClient: HttpClient) {
}

  // Beneficiary
  createBeneficiary(beneficiary: SaveBeneficiaryModel): Promise<SaveBeneficiaryModel> {
    return this._httpClient.post<SaveBeneficiaryModel>(this.primaryRoute + ApiRoute.SAVE_BENEFICIARY, beneficiary).toPromise();
  }

  updateBeneficiary(id: string, beneficiary: SaveBeneficiaryModel): Promise<SaveBeneficiaryModel> {
    return this._httpClient.put<SaveBeneficiaryModel>(this.primaryRoute + ApiRoute.UPDATE_BENEFICIARY + id, beneficiary).toPromise();
  }
  
  getAllBeneficiaries(): Promise<BeneficiaryModel[]> {
    return this._httpClient.get<BeneficiaryModel[]>(this.primaryRoute + ApiRoute.GET_BENEFICIARIES).toPromise();
  }

  getAllBeneficiariesGrid(request: GridQueryModel): Promise<any[]> {
    return this._httpClient.post<BeneficiaryModel[]>(this.primaryRoute + ApiRoute.GET_BENEFICIARIES_ALL, request).toPromise();
  }

  getBeneficiary(id: string): Promise<GetBeneficiaryResponse> {
    return this._httpClient.get<GetBeneficiaryResponse>(this.primaryRoute + ApiRoute.GET_BENEFICIARY + id).toPromise();
  }

  getCustomerList(): Promise<BeneficiaryCustomerListModel[]> {
    return this._httpClient.get<BeneficiaryCustomerListModel[]>(this.primaryRoute + ApiRoute.GET_CUSTOMER_LIST).toPromise();
  }

  // Education Details
  createBeneficiaryEducation(id: string, education: SaveBeneficiaryEducationModel): Promise<SaveBeneficiaryEducationModel> {
    return this._httpClient.post<SaveBeneficiaryEducationModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BENEFICIARY_EDU}`, education).toPromise();
  }

  updateBeneficiaryEducation(id: string, education: SaveBeneficiaryEducationModel): Promise<SaveBeneficiaryEducationModel> {
    return this._httpClient.put<SaveBeneficiaryEducationModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BENEFICIARY_EDU}/${education.BeneficiaryEducation.educationDetailId}`, education).toPromise();
  }

  // Work Experience
  createBeneficiaryWorkExperience(id: string, work: SaveWorkExperienceDetailModel): Promise<SaveWorkExperienceDetailModel> {
    return this._httpClient.post<SaveWorkExperienceDetailModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BENEFICIARY_WORK_EXP}`, work).toPromise();
  }

  updateBeneficiaryWorkExperience(id: string, work: SaveWorkExperienceDetailModel): Promise<SaveWorkExperienceDetailModel> {
    return this._httpClient.put<SaveWorkExperienceDetailModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BENEFICIARY_WORK_EXP}/${work.WorkExperienceDetail.workExperienceId}`, work).toPromise();
  }

  // Document
  createDocument(id: string, document: DocumentModel) : Promise<{ Document: DocumentModel }>{
    return this._httpClient.post<{ Document: DocumentModel }>(`${this.primaryRoute}/${id}/document`, {Document: document}).toPromise();
  }

  deleteDocument(id: string, entityId: string, documentId: string) {
    return this._httpClient.delete(`${this.primaryRoute}/${id}/document/${entityId}/${documentId}`).toPromise();
  }

  // Passport Detail
  createPassportDetail(id: string, request: SavePassportModel): Promise<PassportModel> {
    return this._httpClient.post<PassportModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_PASSPORT}`, request).toPromise();
  }

  updatePassportDetail(id: string, request: SavePassportModel): Promise<PassportModel> {
    return this._httpClient.put<PassportModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_PASSPORT}/${request.Passport.passportDetailId}`, request).toPromise();
  }

  // Visa Detail
  createVisaDetail(id: string, request: SaveVisaModel): Promise<VisaModel> {
    return this._httpClient.post<VisaModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_VISA}`, request).toPromise();
  }

  updateVisaDetail(id: string, request: SaveVisaModel): Promise<VisaModel> {
    return this._httpClient.put<VisaModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_VISA}/${request.Visa.visaDetailId}`, request).toPromise();
  }

  // I94 Detail
  createI94Detail(id: string, request: SaveI94Model): Promise<I94Model> {
    return this._httpClient.post<I94Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_I94}`, request).toPromise();
  }

  updateI94Detail(id: string, request: SaveI94Model): Promise<I94Model> {
    return this._httpClient.put<I94Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_I94}/${request.I94.i94DetailId}`, request).toPromise();
  }

  // Lca Detail
  createLcaDetail(id: string, request: SaveLcaModel): Promise<LcaModel> {
    return this._httpClient.post<LcaModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_LCA}`, request).toPromise();
  }

  updateLcaDetail(id: string, request: SaveLcaModel): Promise<LcaModel> {
    return this._httpClient.put<LcaModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_LCA}/${request.Lca.lcaDetailId}`, request).toPromise();
  }

  // H1b Detail
  createH1bDetail(id: string, request: SaveH1bModel): Promise<H1bModel> {
    return this._httpClient.post<H1bModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_H1B}`, request).toPromise();
  }

  updateH1bDetail(id: string, request: SaveH1bModel): Promise<H1bModel> {
    return this._httpClient.put<H1bModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_H1B}/${request.H1b.h1bDetailId}`, request).toPromise();
  }

  // F1 Detail
  createF1Detail(id: string, request: SaveF1Model): Promise<F1Model> {
    return this._httpClient.post<F1Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_F1}`, request).toPromise();
  }

  updateF1Detail(id: string, request: SaveF1Model): Promise<F1Model> {
    return this._httpClient.put<F1Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_F1}/${request.F1.f1DetailId}`, request).toPromise();
  }

  // Gc Detail
  createGcDetail(id: string, request: SaveGcModel): Promise<GcModel> {
    return this._httpClient.post<GcModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_GC}`, request).toPromise();
  }

  updateGcDetail(id: string, request: SaveGcModel): Promise<GcModel> {
    return this._httpClient.put<GcModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_GC}/${request.Gc.gcDetailId}`, request).toPromise();
  }

  // I485 Detail
  createI485Detail(id: string, request: SaveI485Model): Promise<I485Model> {
    return this._httpClient.post<I485Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_I485}`, request).toPromise();
  }

  updateI485Detail(id: string, request: SaveI485Model): Promise<I485Model> {
    return this._httpClient.put<I485Model>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_I485}/${request.I485.i485DetailId}`, request).toPromise();
  }

  // Beneficiary Dependent/Family Detail
  createFamilyDetail(id: string, request: SaveBeneficiaryDependentModel): Promise<BeneficiaryDependentModel> {
    return this._httpClient.post<BeneficiaryDependentModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BD}`, request).toPromise();
  }

  updateFamilyDetail(id: string, request: SaveBeneficiaryDependentModel): Promise<BeneficiaryDependentModel> {
    return this._httpClient.put<BeneficiaryDependentModel>(`${this.primaryRoute}/${id}${ApiRoute.SAVE_BD}/${request.BeneficiaryDependent.dependentId}`, request).toPromise();
  }

  updateDocument(id: string, document: any): Promise<any> {
    return this._httpClient.put(`${this.primaryRoute}/${id + ApiRoute.DOCUMENT}/${document.documentId}`, {Document: document}).toPromise();
  }

  deleteBeneficiary(id: string): Promise<any> {
    return this._httpClient.delete<any>(this.primaryRoute + ApiRoute.DELETE_BENEFICIARY + id).toPromise();
  }

  saveH4Details(id: String, request: any) : Promise<any> {
    return this._httpClient.post(`${this.primaryRoute}/${id + ApiRoute.SAVE_H4_DETAILS}`, request).toPromise();
  }

  getLcaDetail(id: string): Promise<any> {
    return this._httpClient.get<any>(this.primaryRoute + ApiRoute.GET_LCA_DETAIL + id).toPromise();
  }

  getBasicBeneficiaries(): Promise<BeneficiaryModel[]> {
    return this._httpClient.get<BeneficiaryModel[]>(this.primaryRoute + ApiRoute.GET_BASIC_BENEFICIARIES).toPromise();
  }

  getVisaDetail(id : string) : Promise<any> {
    return this._httpClient.get<any>(this.primaryRoute + ApiRoute.GET_VISA_DETAIL + id).toPromise();
  }

  getBeneficiariesBasicByEmail(email: string): Promise<GetBeneficiaryResponse> {
    return this._httpClient.get<GetBeneficiaryResponse>(this.primaryRoute + ApiRoute.GET_BENE_EMAIL + email).toPromise();
  }
  getSpouseEmail(request, headers): Promise<any> {
    return this._httpClient.post<any>(this.primaryRoute + ApiRoute.GET_SPOUSE_EMAIL, request, headers).toPromise();
  }
  
  async setActiveTab (tab){
    this.activeTab = tab
  }
   getActiveTab (){
   return  this.activeTab;
  }

  async ackAlert(request: any, id: string): Promise<any> {
    return this._httpClient.put(`${this.primaryRoute + ApiRoute.BENEFICIARY_UPDATE_ALERT}/${id}`, request).toPromise();
  }

  deleteH1bDetail(h1bId: string,): Promise<any> {
    return this._httpClient.delete(this.primaryRoute+`${ApiRoute.DELETE_H1B}/${h1bId}`).toPromise();
  }

  getCaseDocuments(id : string) : Promise<any> {
    return this._httpClient.get<any>(`${this.primaryRoute}/${id + ApiRoute.GET_BENE_CASE_DOCUMETNS}`).toPromise();

  }

  async uploadBene(request : any): Promise<any> {
    return this._httpClient.post(`${this.primaryRoute + ApiRoute.BENEFICIARY_UPLOAD }`, request).toPromise();
  }
}
