<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
	<app-content-header [contentHeader]=contentHeader></app-content-header>
</div>
<div class="row">
	<div class="col-md-6">
		<label class="label-font-weight">User</label>
		<ng-select (change)="handleUserChange($event)" [(ngModel)]="selectedUser">
		<ng-option *ngFor="let user of users" [value]="user.userId">{{user.firstName}} {{user.lastName}}</ng-option>
		</ng-select>
    <div class="p-10" *ngIf="selectedUser">User permission only shows extra permissions given. </div>
	</div>
	<div class="col-md-6">
		<label class="label-font-weight" for="role">Role</label>
		<ng-select (change)="handleRoleChange($event)" [(ngModel)]="selectedRole">
		<ng-option *ngFor="let role of roles" [value]="role.role_name">{{role.role_name}}</ng-option>
		</ng-select>
		<br />
		<div *ngIf="selectedRole">
			<button (click)="openCopyDialog()" class="btn btn-primary">Copy permission from {{selectedRole}}</button>
		</div>
	</div>
</div>
<br />
<br />
<div id="stepperLead1" class="bs-stepper horizontal-wizard-example">
	<div class="bs-stepper-header">
		<div class="step" data-target="#beneficiary">
			<button class="step-trigger"(click)="tabChange(1)">
			<span class="bs-stepper-box">
			<i class="feather icon-user"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Beneficiary</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#case">
			<button class="step-trigger"(click)="tabChange(2)">
			<span class="bs-stepper-box">
			<i class="feather icon-briefcase"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Case</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#lead">
			<button class="step-trigger"(click)="tabChange(3)">
			<span class="bs-stepper-box">
			<i class="feather icon-award"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Lead</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#customer">
			<button class="step-trigger"(click)="tabChange(4)">
			<span class="bs-stepper-box">
			<i class="feather icon-users"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Customer</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#user">
			<button class="step-trigger"(click)="tabChange(5)">
			<span class="bs-stepper-box">
			<i class="feather icon-user-check"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">User</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#audit_management">
			<button class="step-trigger"(click)="tabChange(6)">
			<span class="bs-stepper-box">
			<i class="feather icon-activity"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Audit Management</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#admin_management">
			<button class="step-trigger"(click)="tabChange(7)">
			<span class="bs-stepper-box">
			<i class="feather icon-settings"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Admin Management</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#folder_management">
			<button class="step-trigger"(click)="tabChange(8)">
			<span class="bs-stepper-box">
			<i class="feather icon-settings"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Folder Management</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
		<div class="step" data-target="#report_management">
			<button class="step-trigger"(click)="tabChange(9)">
			<span class="bs-stepper-box">
			<i class="feather icon-settings"></i>
			</span>
			<span class="bs-stepper-label">
			<span class="bs-stepper-title">Report Management</span>
			</span>
			</button>
		</div>
		<div class="line"></div>
	</div>
	<div class="bs-stepper-content">
		<div id="beneficiary" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('beneficiary')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="case" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('case')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="lead" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('lead')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="customer" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('customer')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="user" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('user')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="audit_management" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('audit_management')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="admin_management" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('admin_management')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="folder_management" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('folder')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
		<div id="report_management" class="content">
			<div class="permission-box" *ngFor="let permission of getPermissions('report_management')">
				<input type="checkbox"
              class="custom-control-input"
              [id]="permission.permission_id"
              name="active"
              [checked]="isChecked(permission)"
              (change)="handlePermissionChange(permission)"/>
				<label class="custom-control-label" [for]="permission.permission_id">{{permission.permission_name}}</label>
			</div>
		</div>
	</div>
	<div class="p-20">
    <br>
		<button type="button" class="btn btn-primary" (click)="savePermissions()">Save</button>
    <br>
	</div>
</div>