import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { ContextMenusModule } from 'app/main/extensions/context-menu/context-menu.module';
import { ToastrsModule } from 'app/main/extensions/toastr/toastr.module';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NotesComponent } from './notes/notes.component';

@NgModule({
  declarations: [NotesComponent],
  imports: [
    ToastrsModule,
    ContextMenusModule,
    CommonModule,
    CoreCommonModule,
    FormsModule,
    NgbModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    ReactiveFormsModule,
  ],
  exports: [NotesComponent]
})
export class ExtensionsModule {}
