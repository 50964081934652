import { Component, OnInit } from '@angular/core';
import Stepper from 'bs-stepper';
import { PERMISSIONS } from '../../constants';
import { UserManagementService } from 'app/main/apps/user-management/user-management.service';
import { AdminConfigService } from 'app/main/apps/services/admin-config.service';
import { PermissionService } from '../../permisson.service';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
import { CopyPermissionComponent } from '../copy-permission/copy-permission.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

// Show user role in permission manager

@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss']
})
export class PermissionManagementComponent implements OnInit {

  public contentHeader: object;
  public horizontalWizardStepper: Stepper;
  public selectedOption
  public selectedUser
  public selectedRole
  public users = []
  public roles = []
  public currentPermissions = new Set()
  active = 1;
  entities = [
    'beneficiary',
    'case',
    'lead',
    'customer',
    'user',
    'audit_management',
    'admin_management',
    'folder',
    'report_management'
  ]
  constructor(private userManagementService: UserManagementService,
              private adminConfigService: AdminConfigService,
              private permissionService: PermissionService,
              private route: ActivatedRoute,
              private modalService: NgbModal,
    ) { 
    this.contentHeader = {
      headerTitle: 'Manage Permissions',
      actionButton: false,
      breadcrumb: {
        type: '',
        links: [
        ]
      }
    };

  }

  async ngOnInit() {
    const options = {
      linear: false,
      animation: true
    };
    
    this.horizontalWizardStepper = new Stepper(document.querySelector('#stepperLead1'), options);
    this.horizontalWizardStepper.to(1);
    let users = await this.userManagementService.getActiveUsers()
    this.users = users.filter(user => user.role !== 'Employer' && user.role !== 'Beneficiary')
    let roles = await this.adminConfigService.getRoles()
    this.roles = roles.filter(role => role.role_name !== 'Employer' && role.role_name !== 'Beneficiary')
    if (this.route.snapshot.queryParams['userId']) {
      this.selectedUser = this.route.snapshot.queryParams['userId']
      this.handleUserChange(this.selectedUser)
    } else if(this.route.snapshot.queryParams['role']) {
      this.selectedRole = this.route.snapshot.queryParams['role']
      this.handleRoleChange(this.selectedRole)
    }
  }

  tabChange(index) {
    this.horizontalWizardStepper.to(index);
  }

  getPermissions(entity) {
    return PERMISSIONS.filter(permission => permission.entity === entity)
  }

  isChecked(permission) {
    return this.currentPermissions.has(permission.permission_id)
  }

  handlePermissionToggle(permission) {
    this.currentPermissions[permission.permission_id] = !this.currentPermissions[permission.permission_id]
  }
  async handleUserChange(selectedUser){
    this.selectedUser = selectedUser
    this.selectedRole = null
    this.currentPermissions = new Set()
    let userPermissions:any = await this.permissionService.getInlineUserPermissions(selectedUser)
    this.currentPermissions = new Set(userPermissions)
  }
  async handleRoleChange(selectedRole){
    this.selectedRole = selectedRole
    this.selectedUser = null
    this.currentPermissions = new Set()
    let rolePermissions:any = await this.permissionService.getRolePermissions(selectedRole)
    this.currentPermissions = new Set(rolePermissions)
  }

  handlePermissionChange(permission) {
    this.currentPermissions.has(permission.permission_id) ? this.currentPermissions.delete(permission.permission_id) : this.currentPermissions.add(permission.permission_id)
  }

  async savePermissions() {
    let permissions = Array.from(this.currentPermissions)
    if (this.selectedUser) {
      await this.permissionService.updateUserPermission(this.selectedUser, permissions)
      this.showSavedSuccess()
    } else if (this.selectedRole) {
      await this.permissionService.updateRolePermissions(this.selectedRole, permissions)
      this.showSavedSuccess()
    }
  }

  showSavedSuccess() {
    Swal.fire({
      title: 'Success',
      text: 'Permissions saved successfully',
      icon: 'success',
      timer: 1000,
      showConfirmButton: false
    })
  }

  async openCopyDialog() {
    let modalRef = this.modalService.open(CopyPermissionComponent)
    modalRef.componentInstance.fromRole = this.selectedRole
    modalRef.componentInstance.roles = this.roles
    modalRef.componentInstance.onSubmit.subscribe(async (toRole) => {
      if (toRole) {
        let currentRolePermissions = Array.from(this.currentPermissions)
        await this.permissionService.updateRolePermissions(toRole, currentRolePermissions)
        this.showSavedSuccess()
      }
      modalRef.close()
    })
  }

}
