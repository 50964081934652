import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
@Injectable({
  providedIn: 'root'
})
export class EmailService {

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
   constructor(private _httpClient: HttpClient) {
}


  getEmailTemplates(type): Promise<any> {
    return this._httpClient.get(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_EMAIL_TEMPLATES+ '/'+ type).toPromise();
  }

  getEmailSignatures(id): Promise<any> {
    return this._httpClient.get(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_EMAIL_SIGNATURES + '/'+ id).toPromise();
  }
  getEmailSMTPS(id): Promise<any> {
    return this._httpClient.get(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_EMAIL_SMTPS + '/'+ id).toPromise();
  }

  
  getMails(req): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.SENT_MAILS, req ).toPromise();
  }

  sendEmail(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.POST_EMAIL,body).toPromise()
  }

  saveSmtp(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.POST_SMTP,body).toPromise()
  }
  saveSignature(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.POST_SIGNATURE,body).toPromise()
  }
  
  updateSmtp(body): Promise<any> {
    return this._httpClient.put(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.UPDATE_SMTP + '/'+ body.id,body).toPromise()
  }
  updateSignature(body): Promise<any> {
    return this._httpClient.put(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.UPDATE_SIGNATURE + '/'+ body.id,body).toPromise()
  }

  deleteSmtp(body): Promise<any> {
    return this._httpClient.delete(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.DELETE_SMTP + '/'+ body.id).toPromise()
  }

  deleteSignature(body): Promise<any> {
    return this._httpClient.delete(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.DELETE_SIGNATURE + '/'+ body.id).toPromise()
  }

  testSmtp(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.TEST_SMTP,body).toPromise()
  }

  updateSentMail(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.UPDATE_SENT_EMAIL,body).toPromise()
  }

  sendInvoice(body): Promise<any> {
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.SENT_INVOICE,body).toPromise()
  }
}