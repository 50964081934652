import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { NgxMaskModule } from 'ngx-mask';

import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { QuillModule } from 'ngx-quill';
import { LayoutModule } from 'app/layout/layout.module';
import { AppFormsModule } from 'app/main/forms/Appforms.module';
import { CoreCardModule } from '@core/components/core-card/core-card.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { ExtensionsModule } from 'app/main/extensions/extensions.module';
import { PipeModule } from 'app/pipes/pipe.module';
import { FileUploadModule } from 'ng2-file-upload';
import { RoleAssociationComponent } from './role-association/role-association.component';
import { UserManagementService } from './user-management.service';
import { RoleAssocSidebarComponent } from './role-association/role-assoc-sidebar/role-assoc-sidebar.component';
import { AdminConfigService } from '../services/admin-config.service';
import { AddNewUserSidebarComponent } from './role-association/add-new-user-sidebar/add-new-user-sidebar.component';
import { BlockUIModule } from 'ng-block-ui';
import { AddNewTeamSidebarComponent } from './role-association/add-new-team-sidebar/add-new-team-sidebar.component';
import { CustomerManagementService } from '../customer-management/customer-management.service';
import { BeneficiaryManagementService } from '../beneficiary-managment/beneficiary-management.service';


// routing
const routes: Routes = [
  {
    path: 'role-association',
    component: RoleAssociationComponent,
    data: { animation: 'RoleAssociationComponent' }
  }
];

@NgModule({
  declarations: [
    RoleAssociationComponent,
    RoleAssocSidebarComponent,
    AddNewUserSidebarComponent,
    AddNewTeamSidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreCommonModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    Ng2FlatpickrModule,
    NgxDatatableModule,
    CorePipesModule,
    CoreDirectivesModule,
    CoreSidebarModule,
    ContentHeaderModule,
    QuillModule.forRoot(),
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    CoreThemeCustomizerModule,
    LayoutModule,
    AppFormsModule,
    CoreCardModule,
    SweetAlert2Module,
    ExtensionsModule,
    PipeModule.forRoot(),
    FileUploadModule,
    BlockUIModule
  ],
  providers: [UserManagementService, AdminConfigService, CustomerManagementService, BeneficiaryManagementService]
})
export class UserManagementModule { }
