// export class EventRef {
//   id? = undefined;
//   url: string;
//   title: string = '';
//   userId: string;
//   calType: string;
//   linkID: string;
//   location: string;
//   reminder: boolean;
//   period: string;
//   start: Date;
//   end: Date;
//   done: boolean;
//   recurring: boolean;
//   createDate: string;
//   modifiedDate: string;
//   private: boolean;
//   description: string;
// }


export class EventRef {
  id? = undefined;
  url: string;
  title: string = '';
  start: string;
  end: string;
  allDay = false;
  calendar: string;
  extendedProps = {
    location: '',
    description: '',
    period: '',
    userId: [],
    linkId: '',
    recurring: false,
    reminder: false,
    done: false,
    createDate: new Date(),
    modifiedDate: new Date(),
    private: false,
    reminderDateTime: '',
    toEmails: []
  };
}