import { NgModule }      from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { PhonePipe } from './phone.pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
    imports:        [],
    declarations:   [KeysPipe,PhonePipe, SafePipe],
    exports:        [KeysPipe,PhonePipe],
})

export class PipeModule {

  static forRoot() {
     return {
         ngModule: PipeModule,
         providers: [],
     };
  }
} 