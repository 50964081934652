export class EmployeeFakeData {
  public static employees = [
    {
      id: 4987,
      issuedDate: '13 Dec 2019',
      client: {
        address: '7777 Mendez Plains',
        company: 'Hall-Robbins PLC',
        companyEmail: 'don85@johnson.com',
        country: 'United States of America',
        contact: '(616) 865-4180',
        name: 'Jordan Stevenson'
      },
      service: 'Software Development',
      total: 3428,
      avatar: '',
      invoiceStatus: 'Active',
      balance: '$724',
      dueDate: '23 Apr 2019',
      role: 'Attorney Admin'
    },
    {
      id: 4988,
      issuedDate: '17 Jul 2019',
      client: {
        address: '04033 Wesley Wall Apt. 961',
        company: 'Mccann LLC and Sons',
        companyEmail: 'brenda49@taylor.info',
        country: 'Haiti',
        contact: '(226) 204-8287',
        name: 'Stephanie Burns'
      },
      service: 'UI/UX Design & Development',
      total: 5219,
      avatar: 'assets/images/avatars/10-small.png',
      invoiceStatus: 'InActive',
      balance: 0,
      dueDate: '15 Dec 2019',
      role: 'Attorney Admin'
    },
    {
      id: 4989,
      issuedDate: '19 Oct 2019',
      client: {
        address: '5345 Robert Squares',
        company: 'Leonard-Garcia and Sons',
        companyEmail: 'smithtiffany@powers.com',
        country: 'Denmark',
        contact: '(955) 676-1076',
        name: 'Tony Herrera'
      },
      service: 'Unlimited Extended License',
      total: 3719,
      avatar: 'assets/images/avatars/1-small.png',
      invoiceStatus: 'Active',
      balance: 0,
      dueDate: '03 Nov 2019',
      role: 'Attorney Admin'
    }
  ];
}
