<div class="slideout-content">
  <!-- Modal to add new user starts-->
  <div class="modalsd modal-slide-in sdfade new-user-modal" id="modals-slide-in">
    <div class="modal-dialog">
      <form class="add-new-user modal-content pt-0" (ngSubmit)="(newRoleForm.form.valid)" #newRoleForm="ngForm">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
          (click)="toggleSidebar('add-new-user-sidebar')">
          ×
        </button>
        <div class="modal-header mb-1">
          <h5 class="modal-title" id="exampleModalLabel">Create User</h5>
        </div>
        <div class="modal-body flex-grow-1">
          <div class="form-group">
            <label class="form-label" for="Role">Role</label>
            <ng-select #selectMultiLimited placeholder="Roles" [items]="roles" [multiple]="false" bindLabel="role_name"
              [(ngModel)]="selectedRoles" #FRolesRef="ngModel" name="roles" required
              [class.error]="newRoleForm.submitted && FRolesRef.invalid" (change)="changeDropdown()">
            </ng-select>
            <span *ngIf="newRoleForm.submitted && FRolesRef.invalid" class="invalid-form"> <small
                class="form-text text-danger" *ngIf="FRolesRef.errors.required">Role is required!</small>
            </span>
          </div>
          <div class="form-group" *ngIf="this.selectedRoles?.role_name === 'Beneficiary'">
            <label class="form-label" for="Beneficiary">Beneficiary</label>
            <ng-select #selectMultiLimited placeholder="Beneficiary" [items]="beneficiries" [multiple]="false"
              bindLabel="description" [(ngModel)]="selectedbeneficiary"
              [class.error]="newRoleForm.submitted && FBeneficiary.invalid" #FBeneficiary="ngModel" name="beneficiary"
              [disabled]="isDisabled" [required]="!isDisabled" (change)="getBeneficiaryDetails()">
            </ng-select>
            <span *ngIf="newRoleForm.submitted && FBeneficiary.invalid" class="invalid-form"> <small
                class="form-text text-danger" *ngIf="FBeneficiary.errors.required">Beneficiary is required!</small>
            </span>
          </div>
          <div class="form-group"
            *ngIf="(this.selectedRoles?.role_name === 'Beneficiary' || this.selectedRoles?.role_name === 'Employer') &&  this.isVisible">
            <label class="form-label" for="Employer">Employer</label>
            <ng-select #selectMultiLimited placeholder="Employer" [items]="customers" [multiple]="false"
              bindLabel="customerName" [(ngModel)]="selectedCustomer"
              [class.error]="newRoleForm.submitted && FCustomerRef.invalid" #FCustomerRef="ngModel" name="customer"
              [required]="isVisible"
              required [disabled]="isDisabled " (change)="getCompanyDetails()">
            </ng-select>
            <span *ngIf="newRoleForm.submitted && FCustomerRef.invalid" class="invalid-form"> <small
                class="form-text text-danger" *ngIf="FCustomerRef.errors.required">Customer is required!</small>
            </span>
          </div>

          <div class="form-group">
            <label class="form-label" for="firstName">First Name</label>
            <input type="text" inputRef class="form-control dt-full-name" id="firstName" placeholder="First Name"
              name="firstName" [(ngModel)]="firstName" [class.error]="newRoleForm.submitted && FFirstNameRef.invalid"
              #FFirstNameRef="ngModel" required aria-describedby="basic-icon-default-fullname2"
              [disabled]="empBeniFlag" />
            <span *ngIf="newRoleForm.submitted && FFirstNameRef.invalid" class="invalid-form"> <small
                class="form-text text-danger" *ngIf="FFirstNameRef.errors.required">First Name is required!</small>
            </span>
          </div>
          <div class="form-group"> <label class="form-label" for="lastName">Last Name</label> <input type="text" inputRef
              class="form-control dt-full-name" id="lastName" placeholder="Last Name" name="lastName"
              [(ngModel)]="lastName" [class.error]="newRoleForm.submitted && FLastNameRef.invalid"
              #FLastNameRef="ngModel" required aria-describedby="basic-icon-default-fullname2"
              [disabled]="empBeniFlag" /> <span *ngIf="newRoleForm.submitted && FLastNameRef.invalid"
              class="invalid-form"> <small class="form-text text-danger" *ngIf="FLastNameRef.errors.required">Last Name
                is required!</small> </span>
          </div>
          <div class="form-group"> <label class="form-label" for="userEmail">Email</label> <input type="email"
              class="form-control dt-full-name" id="userEmail" placeholder="Email" name="userEmail"
              [(ngModel)]="userEmail" [class.error]="newRoleForm.submitted && FUserEmailRef.invalid"
              #FUserEmailRef="ngModel" required email aria-describedby="basic-icon-default-fullname2"
              [disabled]="empBeniFlag" /> <span *ngIf="newRoleForm.submitted && FUserEmailRef.invalid"
              class="invalid-form"> <small class="form-text text-danger" *ngIf="FUserEmailRef.errors.required">Last Name
                is required!</small> <small class="form-text text-danger" *ngIf="FUserEmailRef.errors.email">email is
                invalid!</small> </span>
          </div>
          <div class="form-group"> <label class="form-label" for="userPhonenumber">Phone Number</label> <input
              type="text" inputRef class="form-control dt-full-name" id="userPhonenumber" placeholder="+0 (000) 000 0000"
              name="userPhonenumber" [(ngModel)]="userPhonenumber"
              [class.error]="newRoleForm.submitted && FUserPhonenumberRef.invalid" #FUserPhonenumberRef="ngModel"
              required mask="+0 (000) 000 0000" aria-describedby="basic-icon-default-fullname2"
              [disabled]="empBeniFlag" /> <span *ngIf="newRoleForm.submitted && FUserPhonenumberRef.invalid"
              class="invalid-form"> <small class="form-text text-danger"
                *ngIf="FUserPhonenumberRef.errors.required">Phone Number is required!</small> </span>
          </div>

          <div class="row col-12" style="padding-bottom: 1em;">
            <div class="col-6 custom-control custom-checkbox"> <input type="checkbox" class="custom-control-input"
                id="active" name="active" [(ngModel)]="active" #FActiveRef="ngModel" disabled /> <label
                class="custom-control-label" for="active">Active</label> </div>
          </div>
          <button type="submit" class="btn btn-primary mr-1" (click)="submit(newRoleForm)" rippleEffect>Create</button>
          <button type="reset" class="btn btn-outline-secondary" (click)="toggleSidebar('add-new-user-sidebar')"
            rippleEffect>
            Cancel
          </button>

          <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="errorMessage.length > 0" style="margin-top: 1em;">
            <div class="alert-body">
              <p>{{errorMessage}}</p>
            </div>
          </ngb-alert>
        </div>
      </form>
    </div>
  </div>

  <swal #userSaveAlert title="User created successfully" icon="success" [showConfirmButton]="false" [timer]="1500">
  </swal>
  <!-- Modal to add new user Ends-->
</div>
<block-ui> </block-ui>