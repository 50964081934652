import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreCommonModule } from '@core/common.module';
import { CoreDirectivesModule } from '@core/directives/directives';
import { CorePipesModule } from '@core/pipes/pipes.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormRepeaterModule } from 'app/main/forms/form-repeater/form-repeater.module';
import { NgxMaskModule } from 'ngx-mask';
import { JsonFormComponent } from './json-form/json-form.component';
import { AccGroupJsonFormComponent } from './acc-group-json-form/acc-group-json-form.component';
import { FileUploadFormComponent } from './file-upload-form/file-upload-form.component';
import { QuillModule } from 'ngx-quill';

import { FileSelectDirective, FileUploadModule } from 'ng2-file-upload';
import { CoreCardModule } from '@core/components/core-card/core-card.module';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { DocumentManagementService } from './services/document-management.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TabGroupJsonFormComponent } from './tab-group-json-form/tab-group-json-form.component';
import { ShowErrorComponent } from './show-error/show-error.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgEditorComponent } from './ng-editor/ng-editor.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { UploadFormComponent } from './upload-form/upload-form.component';

@NgModule({
  declarations: [JsonFormComponent, AccGroupJsonFormComponent, FileUploadFormComponent,
     DocumentViewerComponent, TabGroupJsonFormComponent, ShowErrorComponent,UploadFormComponent,
     NgEditorComponent,
     RadioGroupComponent],
  imports: [
    CommonModule, 
    CoreCommonModule,
    FormRepeaterModule,
    FormsModule,
    NgbModule,
    NgSelectModule,
    CorePipesModule,
    CoreDirectivesModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    QuillModule.forRoot(),
    FileUploadModule,
    CoreCardModule,
    NgxDocViewerModule,
    SweetAlert2Module.forRoot(),
    NgxExtendedPdfViewerModule
  ],
  providers: [DocumentManagementService],
  exports: [JsonFormComponent, AccGroupJsonFormComponent, FileUploadFormComponent,UploadFormComponent, DocumentViewerComponent, TabGroupJsonFormComponent]
})
export class AppFormsModule {}
