export class CustomersFakeData {
  public static customers= [
    {
      id: 1,
      company: 'Akula Associates LLC',
      username: 'aallc',
      contact: '(479) 232-9151',
      email: 'info@akulalaw.com',
      currentPlan: 'Enterprise',
      status: 'prospect',
      avatar: ''
    },
    {
      id: 2,
      company: 'Fargoman LLC',
      username: 'fargollc',
      contact: '(479) 232-9151',
      email: 'info@fargollc.com',
      currentPlan: 'Enterprise',
      status: 'hot lead',
      avatar: ''
    },
    {
      id: 3,
      company: 'Oozz PVT LTD',
      username: 'msicely2',
      contact: '(321) 264-4599',
      email: 'msicely2@who.int',
      currentPlan: 'Enterprise',
      status: 'prospect',
      avatar: 'assets/images/avatars/1.png'
    },
    {
      id: 4,
      company: 'Oozz PVT LTD',
      username: 'crisby3',
      contact: '(923) 690-6806',
      email: 'crisby3@wordpress.com',
      currentPlan: 'Team',
      status: 'prospect',
      avatar: 'assets/images/avatars/9.png'
    }
  ];
}
