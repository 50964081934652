export enum Role {
  Admin = 'Admin',
  Client = 'Client',
  User = 'User',
  Employee = 'Employee',
  Attorney = 'Attorney',
  Beneficiary = 'Beneficiary',
  Employer = 'Employer',
  Paralegal = 'Paralegal',
  LegalAssistant = 'Legal Assistant',
  OfficeClerk = 'Office Clerk',
  LawClerk = 'Law Clerk',
  FrontDesk = 'Front Desk',
  AdminAssistant = 'Admin Assistant',
  CaseManager = 'Case Manager'
}
